<template>
  <div class="btn-orange text-center">
      <mdicon name="CashMultiple" class="flex justify-center text-white" size="90" />
      <h2 class=" text-white text-2xl text-center font-semibold mb-4">{{ title }}</h2>
      <a :href="url1" class="text-2xl text-white hover:text-custom-blue">ANTIGO |</a>
      <a :href="url2" class="text-2xl text-white pl-2 hover:text-custom-blue">NOVO</a><br>
      <a :href="url3" class="text-2xl text-center text-white hover:text-custom-blue">ACESSO</a>
  </div>
</template>

<script>
export default {
  data() {
    return {
      url1: 'http://pmdc.modernizacao.com.br:8091/anterior/open.do?action=open&sys=CTP',
      url2: 'http://pmdc.modernizacao.com.br:8151/ctp5/open.do?action=open&sys=CTP',
      url3: 'http://pmdc.modernizacao.com.br:8151/ctp5/open.do?action=open&sys=CTP'
    }
  },
  props: {
    title: String
  },
}
</script>
